import React, { useState } from 'react'
import styled from 'styled-components'
import Data from '../data/diamond-calculator.json'
import { colors, md } from '../common'

export default () => {
  const [ cut, setCut ] = useState('')
  const [ color, setColor ] = useState('')
  const [ clarity, setClarity ] = useState('')
  const [ carat, setCarat ] = useState(0)
  const [ range, setRange ] = useState('')

  return(
    <Wrap>
    <BlockWrap>
    <h2>Shape</h2>
    <div css={`display:flex;${md}{flex-direction:column;}`}>
      <Btn onClick={RoundClick}>
        <NameBlocks active={ cut === 'Round' }>
        <p>Round</p>
        </NameBlocks>
        <img src="https://s3.amazonaws.com/underwoods/calculator/roundcut.png" alt="round cut diamond illustration" />
      </Btn>
      <Btn onClick={EmeraldClick}>
        <NameBlocks active={ cut === 'Emerald' }>
        <p>Emerald</p>
        </NameBlocks>
        <img src="https://s3.amazonaws.com/underwoods/calculator/emeraldcut.png" alt="emerald cut diamond illustration" />
      </Btn>
      <Btn onClick={CushionClick}>
        <NameBlocks active={ cut === 'Cushion' }>
        <p>Cushion</p>
        </NameBlocks>
        <img src="https://s3.amazonaws.com/underwoods/calculator/cushioncut.png" alt="chshion cut diamond illustration" />
      </Btn>
      <Btn onClick={OvalClick}>
        <NameBlocks active={ cut === 'Oval' }>
        <p>Oval</p>
        </NameBlocks>
        <img src="https://s3.amazonaws.com/underwoods/calculator/ovalcut.png" alt="oval cut diamond illustration" />
      </Btn>
    </div>
    </BlockWrap>
    <BlockWrap>
    <h2>Clarity</h2>
    <div css={`display:flex;${md}{flex-direction:column;}`}>
      <Btn onClick={ClickClarity1}><NameBlocks active={ clarity === 'IF' }><p>IF</p></NameBlocks></Btn>
      <Btn onClick={ClickClarity2}><NameBlocks active={ clarity === 'VVS1' }><p>VVS1</p></NameBlocks></Btn>
      <Btn onClick={ClickClarity3}><NameBlocks active={ clarity === 'VVS2' }><p>VVS2</p></NameBlocks></Btn>
      <Btn onClick={ClickClarity4}><NameBlocks active={ clarity === 'VS1' }><p>VS1</p></NameBlocks></Btn>
      <Btn onClick={ClickClarity5}><NameBlocks active={ clarity === 'VS2' }><p>VS2</p></NameBlocks></Btn>
      <Btn onClick={ClickClarity6}><NameBlocks active={ clarity === 'SI1' }><p>SI1</p></NameBlocks></Btn>
      <Btn onClick={ClickClarity7}><NameBlocks active={ clarity === 'SI2' }><p>SI2</p></NameBlocks></Btn>
      <Btn onClick={ClickClarity8}><NameBlocks active={ clarity === 'SI3' }><p>SI3</p></NameBlocks></Btn>
      <Btn onClick={ClickClarity9}><NameBlocks active={ clarity === 'I1' }><p>I1</p></NameBlocks></Btn>
    </div>
    <div css={`display:flex;margin-top:10px;text-align:center;text-transform:uppercase;${md}{display:none}`}>
      <div css={`flex:1;border-top:4px solid #c9d5c4;`}><p>INTERNALLY FLAWLESS</p></div>
      <div css={`flex:2;border-top:4px solid #a3b89b;`}><p>VERY VERY SLIGHTLY INCLUDED</p></div>
      <div css={`flex:2;border-top:4px solid #89a47f;;`}><p>VERY SLIGHTLY INCLUDED</p></div>
      <div css={`flex:3;border-top:4px solid #77966b;`}><p>SLIGHTLY INCLUDED</p></div>
      <div css={`flex:1;border-top:4px solid #77966b;`}><p>INCLUDED</p></div>
    </div>
    </BlockWrap>
    <BlockWrap>
    <h2>Color</h2>
    <div css={`display:flex;${md}{flex-direction:column;}`}>
      <Btn onClick={ClickD}><NameBlocks active={ color === 'D' }><p>D</p></NameBlocks></Btn>
      <Btn onClick={ClickE}><NameBlocks active={ color === 'E' }><p>E</p></NameBlocks></Btn>
      <Btn onClick={ClickF}><NameBlocks active={ color === 'F' }><p>F</p></NameBlocks></Btn>
      <Btn onClick={ClickG}><NameBlocks active={ color === 'G' }><p>G</p></NameBlocks></Btn>
      <Btn onClick={ClickH}><NameBlocks active={ color === 'H' }><p>H</p></NameBlocks></Btn>
      <Btn onClick={ClickI}><NameBlocks active={ color === 'I' }><p>I</p></NameBlocks></Btn>
      <Btn onClick={ClickJ}><NameBlocks active={ color === 'J' }><p>J</p></NameBlocks></Btn>
      <Btn onClick={ClickK}><NameBlocks active={ color === 'K' }><p>K</p></NameBlocks></Btn>
    </div>
    <div css={`display:flex;margin-top:10px;text-align:center;text-transform:uppercase;${md}{display:none}`}>
      <div css={`flex:3;border-top:4px solid #c9d5c4;`}><p>Colorless</p></div>
      <div css={`flex:2;border-top:4px solid #a3b89b;`}><p>Near Colorless</p></div>
      <div css={`flex:2;border-top:4px solid #89a47f;;`}><p>Near Colorless Slight Tint</p></div>
      <div css={`flex:1;border-top:4px solid #77966b;`}><p>Fainted Yellow</p></div>
    </div>
    </BlockWrap>
    <BlockWrap>
      <h2>Carat</h2>
      <input style={{width:'60vw'}} aria-label="carat range selector" type="range" min=".4" value={carat} max="3.99" step=".1" onChange={HandleChange} />
      <p>{carat}</p>
    </BlockWrap>
    <div css={`text-align:center;`}>
      <button css={`outline:none;border:0;padding: .5rem 1rem;font-size:20px;color:white;background-color:${colors.greyDarkest};text-transform:uppercase;`} disabled={ cut === '' || clarity === '' || color === '' || carat === 0 } onClick={Results}>{ (cut === '' || clarity === '' || color === '' || carat === 0) ? 'Make your Selections' : 'See Results'}</button>
      <p css={`font-size:30px;color:${colors.greyDarkest};`}>{range}</p>
    </div>
    </Wrap>
  )

  function RoundClick() { setCut('Round') }
  function EmeraldClick() { setCut('Emerald') }
  function CushionClick() { setCut('Cushion') }
  function OvalClick() { setCut('Oval') }
  function ClickD() { setColor('D') }
  function ClickE() { setColor('E') }
  function ClickF() { setColor('F') }
  function ClickG() { setColor('G') }
  function ClickH() { setColor('H') }
  function ClickI() {setColor('I') }
  function ClickJ() { setColor('J') }
  function ClickK() { setColor('K') }
  function ClickClarity1() { setClarity('IF') }
  function ClickClarity2() { setClarity('VVS1') }
  function ClickClarity3() { setClarity('VVS2') }
  function ClickClarity4() { setClarity('VS1') }
  function ClickClarity5() { setClarity('VS2') }
  function ClickClarity6() { setClarity('SI1') }
  function ClickClarity7() { setClarity('SI2') }
  function ClickClarity8() { setClarity('SI3') }
  function ClickClarity9() { setClarity('I1') }

  function HandleChange(event) {
    setCarat(event.target.value)
  }

  function Results() {
    const result = Data.filter(v => v.shape === cut && v.color === color && v.clarity === clarity && v.high_size >= carat && v.low_size <= carat)
    const lowPrice = result[0].low_price.split(',').join('') * carat
    const highPrice = result[0].high_price.split(',').join('') * carat
    setRange(`$${lowPrice.toLocaleString(undefined, {maximumFractionDigits:2})} - $${highPrice.toLocaleString(undefined, {maximumFractionDigits:2})}`)
  }
}

const Wrap = styled.div`
  padding: 0 8rem;

  ${md} {
    padding: 1rem;
  }
`

const NameBlocks = styled.div`
  background-color: ${props => props.active ? colors.green : colors.greyDarkest };
  color:white;
  padding:.5rem;
  font-size:20px;

  :hover {
    background-color:${colors.green};
  }
`

const Btn = styled.button`
  margin:0;
  border:0;
  padding:0;
  flex:1;
  outline: none;
  cursor:pointer;
  background-color:transparent;
`

const BlockWrap = styled.div`
  text-align:center;
  color:${colors.green};
  text-transform:uppercase;
  margin-bottom: 4rem;
`
